import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import '../styles.css';
import '../Pong.scss';

import { HMSRoomProvider } from '@100mslive/react-sdk';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
	return (
		<SessionProvider session={session}>
			<HMSRoomProvider>
				<Component {...pageProps} />
			</HMSRoomProvider>
		</SessionProvider>
	);
}

export default MyApp;
